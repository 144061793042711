import React from 'react';
// CSS
import './home.css';
// Components
import LandingPage from './home/landingpage';
import Nav from './nav/nav';
import SideNav from './sidenav/sidenav';
import HomeServices from './home/homeservices';
import MissionStatement from './home/missionstatement';
import Footer from './footer/footer';
import Prices from './home/prices';
// Dependencies
import { Col } from 'react-bootstrap';
import { Element } from 'react-scroll';
// imgs
import TempStar from '../assets/tempstar.webp';
import Goodman from '../assets/goodman.webp';
import Grandaire from '../assets/grandaire.webp';
import ServiceContact3 from '../assets/servicecontact3.webp';
import Trane from '../assets/trane.webp';
import TempStarpng from '../assets/tempstar.png';
import Goodmanpng from '../assets/goodman.png';
import Grandairepng from '../assets/grandaire.png';
import Tranepng from '../assets/trane.png';
import ContactService from './contact/contactservice';

function Home() {
  return (
    <div className="home-container">
      <Element name="landingpage" className="anchor"></Element>
      <Nav />
      <LandingPage />
      {/* <SideNav to1="landingpage" to2="certifications" to3="services" /> */}
      <div className="certifications-container">
        <Element name="certifications" className="anchor"></Element>
        <h1 className="cert-h1">Quality A/C Install on the Treasure Coast</h1>
        <div className="certification-logos">
          <Col xl={3} md={6} sm={6} className="logo-col">
            <img
              src={TempStar}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { TempStarpng };
              }}
              alt=""
              className="cert-logo-img"
            />
          </Col>
          <Col xl={3} md={6} sm={6} className="logo-col">
            <img
              src={Goodman}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { Goodmanpng };
              }}
              alt=""
              className="cert-logo-img"
            />
          </Col>
          <Col xl={3} md={6} sm={6} className="logo-col">
            <img
              src={Trane}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { Tranepng };
              }}
              alt=""
              className="cert-logo-img"
            />
          </Col>
          <Col xl={3} md={6} sm={6} className="logo-col">
            <img
              src={Grandaire}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { Grandairepng };
              }}
              alt=""
              className="cert-logo-img"
            />
          </Col>
        </div>
      </div>
      <HomeServices anchor1="services" />
      <MissionStatement />
      <Prices />
      <ContactService bgimg={ServiceContact3} imgbgclass="img-cont" />
      <Footer />
    </div>
  );
}

export default Home;
