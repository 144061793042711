import React from 'react';
// Components
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import ContactService from '../contact/contactservice';
// CSS
import './repair.css';
// imgs
import Bg2 from '../../assets/service2bg.webp';
import RepairBG from '../../assets/repairserviceBG.webp';
import ServiceContact3 from '../../assets/servicecontact3.webp';
import AcGuy from '../../assets/acrepairguy2.webp';
import Bg2png from '../../assets/service2bg.png';
import RepairBGpng from '../../assets/repairserviceBG.png';
import ServiceContact3png from '../../assets/servicecontact3.png';
import AcGuypng from '../../assets/acrepairguy2.png';
// Dependencies
import { Link } from 'react-scroll';           
function Repair() {
  return (
    <div className="repair-service-container">
      <Nav />
      <div className="top-container">
        <div className="service-info">
          <h1 className="service-h1">Repair</h1>
          <p className="service-p">
            Whether your AC quits in the summer or heating quits in the winter,
            we can repair your AC unit 365 days a year with whatever issues
            trouble it. Dont suffer with out AC and call for a consultation now!
          </p>
          <div className="call-container">
            <p className="call-p">Call Now! 24 Hour Same Day Service</p>
            <a href="tel:772-581-2644" className="call-button">
            772-581-2644
            </a>
          </div>
        </div>
        <div className="service-img-cnt">
          <img
            src={AcGuy}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = { AcGuypng };
            }}
            alt="brugger ac repair"
            className="service-img"
          />
        </div>
      </div>

      <div className="service-info-container">
        <img
          src={Bg2}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = { Bg2png };
          }}
          alt="brugger ac repair A/C air conditioner"
          className="service-bg"
        />
        <div className="repair-info-box">
          <div className="info-container">
            <h1 className="quality-h1">Quality repairs</h1>
            <h1 className="main-h1">
              Brugger AC provides the most trustworthy repair services in
              Florida!
            </h1>
            <p className="service-p">
            Working locally in Treasure Coast area for over 20 years, quality work done by the owner himself. Every call and onsite visit is done by Gene who expertly assesses the problem and goes above and beyond to ensure your happiness with your AC. Have your service done by the licensed and insured HVAC technician!
            </p>
            <Link
              to="anchor"
              spy={true}
              smooth={true}
              className="scroll-to-contact"
            >
              Set up an appointment now!
            </Link>
          </div>
          <div className="repair-background-container">
            <img
              src={RepairBG}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { RepairBGpng };
              }}
              alt="brugger repair A/C "
            />
          </div>
        </div>
      </div>
      <ContactService
        bgimg={ServiceContact3}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = { ServiceContact3png };
        }}
        imgbgclass="img-cont"
        anchorpoint="anchor"
      />
      <Footer />
    </div>
  );
}

export default Repair;
