import React from 'react';
// CSS
import '../home.css';
// images
import Ac1 from '../../assets/ac1.webp';
import AcPng from '../../assets/ac1.png';
// Dependencies
import { Link } from 'react-router-dom';
function LandingPage() {
  return (
    <div className="landing-page-container">
      <div className="landing-page-heading">
        <h1 className="brugger-pt1">
          <p className="B">B</p>
          <p className="R">R</p>
          <p className="U">U</p>
          <p className="G">G</p>
        </h1>
        <img
          src={Ac1}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = { AcPng };
          }}
          alt="Brugger air conditioner"
          className="ac-img"
        />
        <h1 className="brugger-pt2">
          <p className="B">G</p>
          <p className="R">E</p>
          <p className="U">R</p>
        </h1>
      </div>
      <div className="button-container">
        <Link to="/Services" className="button-1">
          Services
        </Link>
        <Link to="/Services" className="button-2">
          Contact
        </Link>
      </div>
      <div className="licensed-h1-container">
        <h1 className="licensed-h1">Licensed and insured</h1>
      </div>
    </div>
  );
}

export default LandingPage;
