import React from 'react';
// CSS
import '../home.css';
// Img
import ACguy from '../../assets/acguy.webp';
import ACguy1 from '../../assets/acguy.png';
import BlueDots from '../../assets/bluedots.png';
import OrangeDots from '../../assets/orangedots.png';
import YellowDots from '../../assets/yellowdots.png';
// Dependencies
import { Link } from 'react-router-dom';
function MissionStatement() {
  return (
    <div className="mission-statement-container">
      {/* <img src={BlueDots} alt="" className="bluedots" />
      <img src={YellowDots} alt="" className="orangedots" /> */}
      <div className="mis-state-img-cont">
        <div className=" img-bg-color">
          <div className="img-cnt">
            <img
              src={ACguy}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = { ACguy1 };
              }}
              alt=""
              className="ac-mis"
            />
          </div>
        </div>
      </div>
      <div className="mis-state-content-cont">
        <h1 className="mis-state-header">What we do</h1>
        <div className="line"></div>
        <div className="p-cont">
          <p>
            Having worked locally in Indian River County for over 20 years with
            no negative claims, Gene's extensive experience will be sure to
            result in a solution to your AC problems. All work is done by Gene,
            from your phone call to his hands on the problem, quality work done
            by the owner operator. Gene will go above and beyond providing
            personalization and custom services other larger corporations wont.
            Whether it is Anti-Microbial UV systems, hurricane straps, or sound
            dampeners, Gene will provide the best service for your needs!
          </p>
        </div>
        <div className="service-btn-cont">
          <Link to="/contact" className="service-btn">
            Request a service today!
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MissionStatement;
