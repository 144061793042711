import React, { useState } from 'react';
// imgs
import ContactBg1 from '../../assets/contactbg1.png';
import ContactBg2 from '../../assets/contactbg2.png';
// Dependencies
import emailjs from '@emailjs/browser';
// icons
import { FaPhoneAlt, FaYelp, FaGoogle, FaFacebookF, FaQuestion } from 'react-icons/fa';
import { AiOutlineMail, AiOutlineClose } from 'react-icons/ai';
import { SiNextdoor } from 'react-icons/si';
function Contact(props) {

  const [infoBox, setInfoBox] = useState(false);
  const ToggleInfoBox = () => {
    setInfoBox(!infoBox);
  }

  const [infoText, setInfoText] = useState(false);
  const ToggleInfoText = () => {
    setInfoText(!infoText);
  }
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_r9n0swd',
        'template_cypbmti',
        e.target,
        'user_m1OjxeDWsTAwrOQVAexmw'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div className="contact-form-container">
      <div className={infoBox ? 'img-cont active-info' :'img-cont' }>
        <div className="contact-info">
          <h1 className="contact-h1">Contact information</h1>
          <p className="contact-p">
            Here are some other places you can get in reach with us! we usually
            will respond within 24 hours!
          </p>
          <div className="contact-info">
            <FaPhoneAlt className="contact-icon" />
            <a href="tel:772-581-2644" className="contact-info-link">
              772-581-2644
            </a>
          </div>
          <div className="contact-info">
            <AiOutlineMail className="contact-icon" />
            <a
              href="mailto:bruggerac1@bellsouth.net"
              className="contact-info-link"
            >
              bruggerac1@bellsouth.net
            </a>
          </div>
          <div className="social-links-container">
            <a href="./" className="social-links">
              <FaFacebookF className="social-icons" />
            </a>
            <a href="./" className="social-links">
              <FaYelp className="social-icons" />
            </a>
            <a href="./" className="social-links">
              <SiNextdoor className="social-icons nextdoor" />
            </a>
            <a href="./" className="social-links">
              <FaGoogle className="social-icons" />
            </a>
          </div>
        </div>
      </div>
      <div className="form-container">
        <div className="set-active-btn" onClick={ToggleInfoBox} >
        <FaQuestion className={infoBox ?  'info-icon hidden-icon' : 'info-icon'}  />
        <AiOutlineClose className={infoBox ? 'close-info-icon' : 'close-info-icon hidden-close'} onMouseEnter={ToggleInfoText} onMouseLeave={ToggleInfoText} />
        <p className={infoText ? 'toggle-info-p' : 'toggle-info-p hidden-p'}>{infoBox ? 'Click to close!' : 'Click for more contact info!'}</p>
        </div>
        <h1 className="contact-h1">Contact us!</h1>
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="name-email-cont">
            <div className="input-container">
              <label className="label">Name</label>
              <input
                type="text"
                placeholder="Full Name*"
                name="name"
                className="name-input"
              />
            </div>
            <div className="input-container">
              <label className="label">Email</label>
              <input
                type="text"
                placeholder="Email*"
                name="email"
                className="name-input"
              />
            </div>
          </div>
          <textarea
            name="message"
            placeholder="Message here..."
            className="text-box"
          ></textarea>
          <input type="submit" className="submit-button" />
        </form>
      </div>
    </div>
  );
}

export default Contact;
