import React from 'react';
// Components
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import ContactService from '../contact/contactservice';
// CSS
import './repair.css';
// imgs
import Bg2 from '../../assets/service2bg.webp';
import RepairBG from '../../assets/repairserviceBG2.webp';
import ServiceContact3 from '../../assets/servicecontact3.webp';
import AcGuy from '../../assets/acrepairguy3.webp';
import Bg2Png from '../../assets/service2bg.png';
import RepairBGPng from '../../assets/repairserviceBG2.png';
import ServiceContact3Png from '../../assets/servicecontact3.png';
import AcGuyPng from '../../assets/acrepairguy3.png';

// Dependencies
import {
  Link,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
function Maintenance() {
  return (
    <div className="repair-service-container">
      <Nav />
      <div className="top-container ">
        <div className="service-info">
          <h1 className="service-h1 maintenance">Maintenance</h1>
          <p className="service-p ">
            Working locally in Treasure Coast area for over 20 years, quality
            work done by the owner himself. Every call and onsite visit is done
            by Gene who expertly assesses the problem and goes above and beyond
            to ensure your happiness with your AC. Have your service done by the
            licensed and insured HVAC technician!
          </p>
          <div className="call-container">
            <p className="call-p ">Call Now! 24 Hour Same Day Service</p>
            <a href="tel:772-581-2644" className="call-button">
            772-581-2644
            </a>
          </div>
        </div>
        <div className="service-img-cnt">
          <img
            src={AcGuy}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = { AcGuyPng };
            }}
            alt=""
            className="service-img maintenance"
          />
        </div>
      </div>

      <div className="service-info-container ">
        <img
          src={Bg2}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = { Bg2Png };
          }}
          alt=""
          className="service-bg"
        />
        <div className="repair-info-box ">
          <div className="info-container ">
            <h1 className="quality-h1 ">Quality maintenance</h1>
            <h1 className="main-h1 ">
              Brugger AC provides the most trustworthy maintenance in Florida!
            </h1>
            <p className="service-p ">
              HVAC systems are not invincible, yearly upkeep is required and
              offered from Brugger AC. We will do any and all preventative
              maintenance required for your specific system in order to keep you
              cool in the summer and warm in the winter. Don't abuse your AC and
              give it the chance to fail, please call to inquire about our
              preventative maintenance services in order to keep both you and
              your AC happy!
            </p>
            <Link
              to="anchor"
              spy={true}
              smooth={true}
              className="scroll-to-contact "
            >
              Set up an appointment now!
            </Link>
          </div>
          <div className="repair-background-container">
            <img
              src={RepairBG}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { RepairBGPng };
              }}
              alt=""
            />
          </div>
        </div>
      </div>
      <ContactService
        bgimg={ServiceContact3}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = { ServiceContact3Png };
        }}
        imgbgclass="img-cont"
        anchorpoint="anchor"
      />
      <Footer />
    </div>
  );
}

export default Maintenance;
