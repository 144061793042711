import React, { useState } from 'react';
// CSS
import './services.css';
// Dependencies
import { Col } from 'react-bootstrap';
// Components
import Nav from '../nav/nav';
//
import { Link } from 'react-router-dom';
// icons
import { FaLongArrowAltRight } from 'react-icons/fa';
// imgs
import Repair1 from '../../assets/acrepair1.webp';
import Repair2 from '../../assets/acrepair2.webp';
import Maintenance1 from '../../assets/maintenance1.webp';
import Maintenance2 from '../../assets/maintenance2.webp';
import Install1 from '../../assets/install1.webp';
import Install2 from '../../assets/install2.webp';
import Footer from '../footer/footer';
function Services() {
  const [serviceBox1, setServiceBox1] = useState(true);
  const ToggleDark = () => {
    setServiceBox1(!serviceBox1);
  };
  const [serviceBox2, setServiceBox2] = useState(true);
  const ToggleDark2 = () => {
    setServiceBox2(!serviceBox2);
  };
  const [serviceBox3, setServiceBox3] = useState(true);
  const ToggleDark3 = () => {
    setServiceBox3(!serviceBox3);
  };
  return (
    <div className="services-main">
      <Nav />
      <div className="services-container">
        <div className="services-main-img-cnt">
          <h1 className="services-h1">Services</h1>
        </div>
        <div className="services-box-container">
          <Col xl={6} lg={6} className="service-col">
            <div
              onMouseEnter={ToggleDark}
              onMouseLeave={ToggleDark}
              className={serviceBox1 ? 'service-box' : 'service-box dark-bg'}
            >
              <div className="img-cnt">
                <img
                  src={serviceBox1 ? Repair1 : Repair2}
                  alt=""
                  className="service-img"
                />
              </div>
              <div className="info-container">
                <h1
                  className={serviceBox1 ? 'service-h1' : 'service-h1 dark-h1'}
                >
                  Repair
                </h1>
                <p
                  className={serviceBox1 ? 'service-p' : 'service-p dark-text'}
                >
                 Whether your AC quits in the summer or heating quits in the winter, we can repair your AC unit 365 days a year with whatever issues trouble it. Dont suffer with out AC and call for a consultation now!
                </p>
                <div className="button-container">
                  <Link
                    to="/repair"
                    className={
                      serviceBox1 ? 'service-button' : 'service-button dark-btn'
                    }
                  >
                    Read more
                    <FaLongArrowAltRight
                      className={
                        serviceBox1 ? 'right-arrow' : 'right-arrow dark-arrow'
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} className="service-col">
            <div
              onMouseEnter={ToggleDark2}
              onMouseLeave={ToggleDark2}
              className={serviceBox2 ? 'service-box' : 'service-box dark-bg'}
            >
              <div className="img-cnt">
                <img
                  src={serviceBox2 ? Maintenance1 : Maintenance2}
                  alt=""
                  className="service-img"
                />
              </div>
              <div className="info-container">
                <h1
                  className={serviceBox2 ? 'service-h1' : 'service-h1 dark-h1'}
                >
                  Maintenance
                </h1>
                <p
                  className={serviceBox2 ? 'service-p' : 'service-p dark-text'}
                >
                  Yearly maintenance is a must for all HVAC systems. Scheduled maintenance can help prevent failure of seals, drains, filters, and electrical components to keep your AC blowing cold.
                </p>
                <div className="button-container">
                  <Link
                    to="/maintenance"
                    className={
                      serviceBox2 ? 'service-button' : 'service-button dark-btn'
                    }
                  >
                    Read more
                    <FaLongArrowAltRight
                      className={
                        serviceBox2 ? 'right-arrow' : 'right-arrow dark-arrow'
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} className="service-col">
            <div
              onMouseEnter={ToggleDark3}
              onMouseLeave={ToggleDark3}
              className={serviceBox3 ? 'service-box' : 'service-box dark-bg'}
            >
              <div className="img-cnt">
                <img
                  src={serviceBox3 ? Install1 : Install2}
                  alt=""
                  className="service-img"
                />
              </div>
              <div className="info-container">
                <h1
                  className={serviceBox3 ? 'service-h1' : 'service-h1 dark-h1'}
                >
                  Install
                </h1>
                <p
                  className={serviceBox3 ? 'service-p' : 'service-p dark-text'}
                >
                 Money and energy savings with a modern high efficiency AC are incredible. Along with yearly savings, a new anti-microbial UV system will help to circulate clean air to your household!
                </p>
                <div className="button-container">
                  <Link
                    to="/install"
                    className={
                      serviceBox3 ? 'service-button' : 'service-button dark-btn'
                    }
                  >
                    Read more
                    <FaLongArrowAltRight
                      className={
                        serviceBox3 ? 'right-arrow' : 'right-arrow dark-arrow'
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
