import React from 'react';
// CSS
import './footer.css';
// imgs
import Logo3 from '../../assets/logo3.webp';
import Logo3Png from '../../assets/logo3.png';
//  Icons
import { FaPhoneVolume } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
// Dependencies
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content-cont">
        <div className="first-section">
          <div className="logo-name-container">
            <img
              src={Logo3}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = { Logo3Png };
              }}
              alt=""
              className="logo"
            />
          </div>
          <div className="about-container">
            <h1>About us</h1>
            <p>
              Serving the Treasure Coast with quality AC Repair for over 20
              years. Authorized dealer of high-efficiency tempstar, goodman,
              trane, and grandaire systems. Available 365 days a year 24/7 for
              your needs. License#CAC1817305
            </p>
          </div>
        </div>
        <div className="second-section">
          <h1 className="footer-contact-h1">Contact us</h1>
          <div className="contact-items">
            <FaPhoneVolume className="footer-icon" /> <a href="tel:772-581-2644">772-581-2644</a>
          </div>
          <div className="contact-items">
            <FiMail className="footer-icon" />
            <a  href="mailto:bruggerac1@gmail.com" className="footer-email">bruggerac1@gmail.com</a>
          </div>
        </div>
        <div className="third-section">
          <h1 className="important-links-h1">Important Links!</h1>
          <div className="important-links">
            <Link to="/Services" className="important-link">
              Services
            </Link>
            <Link to="/Maintenance" className="important-link">
              Maintenance
            </Link>
            <Link to="/repair" className="important-link">
              Repair
            </Link>
            <Link to="/about" className="important-link">
              About
            </Link>
            <Link to="/contact" className="important-link">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
