import React from 'react';
// Components
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import ContactService from '../contact/contactservice';
// CSS
import './repair.css';
// imgs
import Bg2 from '../../assets/service3bg.webp';
import RepairBG from '../../assets/repairserviceBG2.webp';
import ServiceContact3 from '../../assets/servicecontact3.webp';
import AcGuy from '../../assets/acrepairguy.webp';
import Bg2png from '../../assets/service3bg.png';
import RepairBGpng from '../../assets/repairserviceBG2.png';
import ServiceContact3png from '../../assets/servicecontact3.png';
import AcGuypng from '../../assets/acrepairguy.png';

// Dependencies
import {
  Link,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
function Install() {
  return (
    <div className="repair-service-container">
      <Nav />
      <div className="top-container ">
        <div className="service-info">
          <h1 className="service-h1 ">Install</h1>
          <p className="service-p ">
            Money and energy savings with a modern high efficiency AC are
            incredible. Along with yearly savings, a new anti-microbial UV
            system will help to circulate clean air to your household!
          </p>
          <div className="call-container">
            <p className="call-p ">Call Now! 24 Hour Same Day Service</p>
            <a href="tel:772-581-2644" className="call-button">
            772-581-2644
            </a>
          </div>
        </div>
        <div className="service-img-cnt">
          <img
            src={AcGuy}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = { AcGuypng };
            }}
            alt="brugger a/c install"
            className="service-img"
          />
        </div>
      </div>

      <div className="service-info-container ">
        <img
          src={Bg2}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = { Bg2png };
          }}
          alt=""
          className="service-bg"
        />
        <div className="repair-info-box ">
          <div className="info-container ">
            <h1 className="quality-h1 ">Quality installation</h1>
            <h1 className="main-h1 ">
              Brugger AC provides the most trustworthy install services in
              Florida!
            </h1>
            <p className="service-p ">
              Brugger Air Conditioning and Heat works hard to bring you quality
              AC systems that will ensure your comfort and add to functionality
              of your home. Our AC systems are all high efficiency, allowing you
              to save money every month on your electric bill. Going above and
              beyond in our installs, we offer services such as UV
              anti-microbial systems, hurricane straps, and sound dampeners
              which other companies don't! Please call today for a free estimate
              on a modern AC system!
            </p>
            <Link
              to="anchor"
              spy={true}
              smooth={true}
              className="scroll-to-contact "
            >
              Set up an appointment now!
            </Link>
          </div>
          <div className="repair-background-container">
            <img
              src={RepairBG}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { RepairBGpng };
              }}
              alt=""
            />
          </div>
        </div>
      </div>
      <ContactService
        bgimg={ServiceContact3}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = { ServiceContact3png };
        }}
        imgbgclass="img-cont"
        anchorpoint="anchor"
      />
      <Footer />
    </div>
  );
}

export default Install;
