import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Pages
import Home from './components/Home';
import Services from './components/services/services';
import Repair from './components/services/repair';
import ScrollToTop from './components/scrolltotop';
import Maintenance from './components/services/maintenance';
import Install from './components/services/install';
import ContactPage from './components/contact/contactpage';
import About from './components/about/about';
function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/Services" exact>
            <Services />
          </Route>
          <Route path="/repair" exact>
            <Repair />
          </Route>
          <Route path="/maintenance" exact>
            <Maintenance />
          </Route>
          <Route path="/install" exact>
            <Install />
          </Route>
          <Route path="/contact" exact>
            <ContactPage />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;

// import React from 'react';

// import { Routes, Route } from 'react-router-dom';

// // Pages
// import Home from './components/Home';
// import Services from './components/services/services';
// import Repair from './components/services/repair';
// import ScrollToTop from './components/scrolltotop';
// function App() {
//   return (
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/Services" element={<Services />} />
//       <Route path="/repair" element={<Repair />} />
//     </Routes>
//   );
// }

// export default App;
