import React from 'react';
// CSS
import './contact.css';
// images

import Contact from './contact';
// Dependencies
import { Element } from 'react-scroll';
function ContactService(props) {
  return (
    <div className="contact-service-container">
      <Contact imgbgclass={props.imgbgclass} />
      <Element name="anchor" className="anchor-point"></Element>
      <img src={props.bgimg} alt="" className="bg" />
    </div>
  );
}

export default ContactService;
