import React, { useState } from 'react';
// CSS
import './nav.css';
// imgs
import Logo from '../../assets/logo1.webp';
import LogoPng from '../../assets/logo1.png';
import Logo2 from '../../assets/logo2.png';
import Logo4 from '../../assets/logo4.png';
// Dependencies
import { Link } from 'react-router-dom';
// icons
import { FaCaretDown } from 'react-icons/fa';
import { HiMenuAlt3 } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
function Nav() {
  const [nav, setNavScroll] = useState('nav-bar');

  const [dropdown, setDropDown] = useState('services-dropdown hidden');

  const [links, setLinks] = useState('link-item');

  const [mobileNav, setMobileNav] = useState(true);

  const [mobileDropDown, setMobileDropDown] = useState(false);
  const ToggleLink = () => {};
  const ToggleDropDown = () => {
    setDropDown('services-dropdown');
    setLinks('link-item white');
  };
  const ToggleExitDropDown = () => {
    setDropDown('services-dropdown hidden');
    setNavScroll('nav-bar');
    setLinks('link-item');
  };

  const ToggleNav = () => {
    setNavScroll('nav-bar active');
    setLinks('link-item white');
  };
  const ToggleNavOff = () => {
    setNavScroll('nav-bar');
  };
  const scrollNav = () => {
    if (window.scrollY >= 10) {
      setNavScroll('nav-bar active');
      setLinks('link-item white');
    } else {
      setNavScroll('nav-bar');
      setLinks('link-item');
    }
  };

  const ToggleMobileNav = () => {
    setMobileNav(!mobileNav);
  };
  const ToggleMobileServiceBox = () => {
    setMobileDropDown(!mobileDropDown);
  };
  window.addEventListener('scroll', scrollNav);
  return (
    <div
      className={nav}
      onMouseLeave={ToggleExitDropDown}
      onMouseEnter={ToggleNav}
    >
      {/* MOBILE NAV MENU OPENER */}
      <div className="nav-menu-container">
        <HiMenuAlt3
          onClick={ToggleMobileNav}
          className={mobileNav ? 'menu-icon' : 'menu-icon hidden-icon'}
        />
        <AiOutlineClose
          onClick={ToggleMobileNav}
          className={mobileNav ? 'close-icon hidden-icon' : 'close-icon'}
        />
      </div>
      {/* MOBILE NAV */}
      <div
        className={
          mobileNav
            ? 'mobile-nav-container'
            : 'mobile-nav-container active-mobile-nav'
        }
      >
        <div className={mobileNav ? 'hidden' : 'link-container'}>
          <Link
            to="/"
            className={mobileNav ? 'mobile-links hidden-links' : 'mobile-links'}
          >
            Home
          </Link>
          <div
            className={mobileNav ? 'mobile-links hidden-links' : 'mobile-links'}
            onClick={ToggleMobileServiceBox}
          >
            Services
            <FaCaretDown
              className={mobileDropDown ? 'caret caret-rotated' : 'caret'}
              onClick={ToggleMobileServiceBox}
            />
          </div>
          {/* Mobile dropdown */}
          <div
            className={
              mobileDropDown
                ? 'services-menu'
                : 'services-menu service-menu-hidden'
            }
          >
            <Link to="/Services" className="service-link">
              Services
            </Link>
            <Link to="/maintenance" className="service-link">
              Maintenance
            </Link>
            <Link to="/install" className="service-link">
              Install
            </Link>
            <Link to="/repair" className="service-link">
              Repair
            </Link>
          </div>
          <Link
            to="/about"
            className={mobileNav ? 'mobile-links hidden-links' : 'mobile-links'}
          >
            About
          </Link>
          <Link
            to="/contact"
            className={mobileNav ? 'mobile-links hidden-links' : 'mobile-links'}
          >
            Contact
          </Link>
        </div>
      </div>
      {/* NAV LOGO */}
      <Link to="/" className="nav-logo">
        <img
          src={Logo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = { LogoPng };
          }}
          alt=""
          className="logo-img"
        />
      </Link>
      {/* NAV DROPDOWN */}
      <div className={dropdown} onMouseLeave={ToggleExitDropDown}>
        <Link to="/maintenance" className="service-link">
          Maintenance
        </Link>
        <Link to="/install" className="service-link">
          Install
        </Link>
        <Link to="/repair" className="service-link">
          Repair
        </Link>
      </div>
      <div className="links">
        <Link className={links} to="/">
          Home
        </Link>
        <Link className={links} to="/Services" onMouseEnter={ToggleDropDown}>
          Services <FaCaretDown className="caretdown" />
        </Link>
        <Link className={links} to="/about">
          About
        </Link>
        <Link className={links} to="/contact">
          Contact
        </Link>
      </div>
    </div>
  );
}

export default Nav;
