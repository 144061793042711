import React, { useState } from 'react';
// CSS
import './sidenav.css';
// dependencies
import {
  Link,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
function SideNav(props) {
  const [sideInfo, setSideInfo] = useState('infotext hidden');
  // link states

  const RevealText1 = () => {
    setSideInfo('infotext');
  };
  const HideText1 = () => {
    setSideInfo('infotext hidden');
  };

  return (
    <div className="side-nav-container">
      <div className="side-nav-text-container">
        <p className={sideInfo}>Landing page</p>
        <p className={sideInfo}>Certifications</p>
        <p className={sideInfo}>Services</p>
        <p className={sideInfo}>another</p>
      </div>
      <div
        className="side-nav-buttons"
        onMouseEnter={RevealText1}
        onMouseLeave={HideText1}
      >
        {/* <Link to={props.to1} className="linkto"></Link> */}
        <Link to={props.to1} spy={true} smooth={true} className="linkto-button">
          <div className="inner-circle"></div>
        </Link>
        <Link
          to={props.to2}
          spy={true}
          smooth={true}
          className="linkto-button"
        ></Link>
        <Link
          to={props.to3}
          spy={true}
          smooth={true}
          className="linkto-button"
        ></Link>
        <Link
          to={props.to4}
          spy={true}
          smooth={true}
          className="linkto-button"
        ></Link>
      </div>
    </div>
  );
}

export default SideNav;
