import React from 'react';
import Footer from '../footer/footer';
import Nav from '../nav/nav';
//
import './about.css';
//
import { Link } from 'react-router-dom';
import Acimg from '../../assets/repairserviceBG3.webp';
import AboutBg from '../../assets/aboutBG.webp';
import LineBg from '../../assets/service3bg.webp';
import AcimgPng from '../../assets/repairserviceBG3.png';
import AboutBgPng from '../../assets/aboutBG.png';
import LineBgPng from '../../assets/service3bg.png';
function About() {
  return (
    <div className="about-page-container">
      <Nav />
      <div className="about-img-cnt">
        <h1 className="about-h1">about</h1>
      </div>
      <div className="about-info-container">
        <img
          src={LineBg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = { LineBgPng };
          }}
          className="about-transition-bg"
          alt="brugger about transition img"
        />
        <img
          src={AboutBg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = { AboutBgPng };
          }}
          className="about-bg"
          alt="brugger about image"
        />
        <div className="about-img-cnt">
          <img
            src={Acimg}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = { AcimgPng };
            }}
            alt=""
          />
        </div>
        <div className="about-info-cnt">
          <h1>About Us</h1>
          <p>
            Having worked locally fixing air conditioning systems in Indian
            River County for over 20 years with no negative claims, Gene's
            extensive experience will be sure to result in a solution to your AC
            problems. All work is done by Gene, from your phone call to his
            hands on the problem, quality work done by the owner operator. Gene
            will go above and beyond providing personalization and custom
            services other larger corporations wont. Whether it is
            Anti-Microbial UV systems, hurricane straps, or sound dampeners,
            Gene will provide the best service for your needs!
          </p>
          <div className="link-container">
            <Link className="link-btn" to="/contact">
              Contact us!
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
