import React from 'react';
import Nav from '../nav/nav';
import Contact from './contact';
import ContactService from './contactservice';
import './contact.css';
import Footer from '../footer/footer';
import ServiceContact3 from '../../assets/servicecontact.webp';

function ContactPage() {
  return (
    <div className="contact-page-container">
      <Nav />
      <ContactService bgimg={ServiceContact3} imgbgclass="img-cont" />
      <Footer />
    </div>
  );
}

export default ContactPage;
