import React, { useState } from 'react';

// CSS
import '../home.css';
// Dependencies
import { Col } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
// Icons
import { FaTools, FaLongArrowAltRight, FaToolbox } from 'react-icons/fa';
import { GiBoxUnpacking, GiAutoRepair } from 'react-icons/gi';
import { MdUpdate } from 'react-icons/md';
import { BsBoxSeam } from 'react-icons/bs';

// imgs
import Blob1 from '../../assets/blob.webp';
import Blob2 from '../../assets/blob1.webp';
import Blob1Png from '../../assets/blob.png';
import Blob2Png from '../../assets/blob1.png';

function HomeServices(props) {
  const [repairLogo, setRepairLogo] = useState(false);
  const ToggleRepairLogo = () => {
    setRepairLogo(!repairLogo);
  };
  const [installLogo, setInstallLogo] = useState(false);
  const ToggleInstallLogo = () => {
    setInstallLogo(!installLogo);
  };
  const [maintenanceLogo, setMaintenanceLogo] = useState(false);
  const ToggleMaintenanceLogo = () => {
    setMaintenanceLogo(!maintenanceLogo);
  };

  return (
    <div className="services-sections">
      <Element className="anchor" name={props.anchor1}></Element>
      <img
        src={Blob2}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = { Blob2Png };
        }}
        className="blob1"
        alt=""
      />
      <img
        src={Blob1}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = { Blob1Png };
        }}
        className="blob2"
        alt=""
      />
      <div className="middle-container">
        <Col
          xl={4}
          lg={6}
          className="service-1"
          onMouseEnter={ToggleRepairLogo}
          onMouseLeave={ToggleRepairLogo}
        >
          <GiAutoRepair
            className={
              repairLogo
                ? 'repair-background-logo'
                : 'repair-background-logo hidden-logo'
            }
          />
          <div className="logo-container">
            <div className="logo">
              <FaTools className="service-icon" />
            </div>
          </div>
          <h1 className="service-header">A/C Repair</h1>
          <p className="service-p">
            Whether your AC quits in the summer or heating quits in the winter,
            we can repair your AC unit 365 days a year with whatever issues
            trouble it. Dont suffer with out AC and call for a consultation now!
          </p>
          <Link to="/repair" className="service-link-container">
            <p>More repair info</p>
            <FaLongArrowAltRight className="service-link-logo" />
          </Link>
        </Col>
        <Col
          xl={4}
          lg={6}
          className="service-1"
          onMouseEnter={ToggleInstallLogo}
          onMouseLeave={ToggleInstallLogo}
        >
          <GiBoxUnpacking
            className={
              installLogo
                ? 'repair-background-logo'
                : 'repair-background-logo hidden-logo'
            }
          />
          <div className="logo-container">
            <div className="logo">
              <BsBoxSeam className="service-icon" />
            </div>
          </div>
          <h1 className="service-header">A/C installation</h1>
          <p className="service-p">
            Money and energy savings with a modern high efficiency AC are
            incredible. Along with yearly savings, a new anti-microbial UV
            system will help to circulate clean air to your household!
          </p>
          <Link to="/install" className="service-link-container">
            <p>more install info</p>
            <FaLongArrowAltRight className="service-link-logo" />
          </Link>
        </Col>
        <Col
          xl={4}
          lg={6}
          className="service-1"
          onMouseEnter={ToggleMaintenanceLogo}
          onMouseLeave={ToggleMaintenanceLogo}
        >
          <FaToolbox
            className={
              maintenanceLogo
                ? 'repair-background-logo'
                : 'repair-background-logo hidden-logo'
            }
          />
          <div className="logo-container">
            <div className="logo">
              <MdUpdate className="service-icon" />
            </div>
          </div>
          <h1 className="service-header">A/C Maintenance</h1>
          <p className="service-p">
            Yearly maintenance is a must for all HVAC systems. Scheduled
            maintenance can help prevent failure of seals, drains, filters, and
            electrical components to keep your AC blowing cold.
          </p>
          <Link to="/maintenance" className="service-link-container">
            <p>More maintenance info</p>
            <FaLongArrowAltRight className="service-link-logo" />
          </Link>
        </Col>
      </div>
    </div>
  );
}

export default HomeServices;
