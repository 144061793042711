import React from 'react';
// CSS
import '../home.css';
// Dependencies
import { Link } from 'react-router-dom';
function Prices() {
  return (
    <div className="prices-container">
      <div className="prices-text-container">
        <h1 className="pricing-header-1">Pricing</h1>
        <h1 className="pricing-header-2">
          All credit cards and debit cards
          <span className="services-span">accepted!</span>
        </h1>
        <p className="pricing-p">
          any onsite service will be $85 (10% discount to all seniors)
        </p>
      </div>
      <div className="prices-inner-container">
        <div className="prices-col one">
          <Link to="/Services" className="big-link"></Link>
          <div className="top-line repair-line"></div>
          <h1 className="prices-h1 repair">Repair</h1>
          {/* <h1 className="price-h1">$85</h1> */}
          <p className="price-p">
            Whether your AC quits in the summer or heating quits in the winter,
            we can repair your AC unit 365 days a year with whatever issues
            trouble it. Dont suffer with out AC and call for a consultation now!
          </p>
          {/* <h3 className="rates-h3">for service tag</h3> */}
          <div className="list-container">
            <div className="list-top-line repair-line"></div>
            <div className="list-bottom-line repair-line"></div>
            {/* <h1 className="list-h1 repair">What you get:</h1> */}
            <ul className="list-items">
              <li>Electric Components</li>
              <li>Thermostats</li>
              <li>Vacuum lines</li>
              <li>Filters</li>
            </ul>
          </div>
          <div className="button-container">
            <Link className="button-link repair-button" to="/">
              Repair
            </Link>
          </div>
        </div>
        <div className="prices-col two">
          <Link to="/Services" className="big-link"></Link>
          <div className="top-line install-line"></div>
          <h1 className="prices-h1 install">Install</h1>
          {/* <h1 className="price-h1">$150.99</h1> */}
          <p className="price-p">
            Money and energy savings with a modern high efficiency AC are
            incredible. Along with yearly savings, a new anti-microbial UV
            system will help to circulate clean air to your household!
          </p>
          {/* <h3 className="rates-h3">rates vary</h3> */}
          <div className="list-container">
            <div className="list-top-line install-line"></div>
            <div className="list-bottom-line install-line"></div>
            {/* <h1 className="list-h1 install">What you get:</h1> */}
            <ul className="list-items">
              <li>Anti Microbial UV System</li>
              <li>1yr Labor Warranty</li>
              <li>Hurricane Straps</li>
              <li>Sound Isolators</li>
            </ul>
          </div>
          <div className="button-container">
            <Link className="button-link install-button" to="/install">
              install
            </Link>
          </div>
        </div>
        <div className="prices-col three">
          <Link to="/maintenance" className="big-link"></Link>
          <div className="top-line maintenance-line"></div>
          <h1 className="prices-h1 maintenance">Maintenance</h1>
          {/* <h1 className="price-h1">$250.99</h1> */}
          <p className="price-p">
            Yearly maintenance is a must for all HVAC systems. Scheduled
            maintenance can help prevent failure of seals, drains, filters, and
            electrical components to keep your AC blowing cold.
          </p>
          {/* <h3 className="rates-h3">rates vary</h3> */}
          <div className="list-container">
            <div className="list-top-line maintenance-line"></div>
            <div className="list-bottom-line maintenance-line"></div>
            {/* <h1 className="list-h1 maintenance">What you get:</h1> */}
            <ul className="list-items">
              <li>Coil Cleaning</li>
              <li>Freon Check</li>
              <li>Electrical inspection</li>
              <li>Thermostat Inspection</li>
            </ul>
          </div>
          <div className="button-container">
            <Link className="button-link maintenance-button" to="/maintenance">
              maintenance
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prices;
